import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Tippy from '@tippyjs/react';

import { black, white } from 'shared/colors';
import 'tippy.js/dist/tippy.css';

interface Props {
  children: React.ReactElement;
}

const TippyTooltip = ({ children }: Props) => {
  return (
    <Tippy
      className={css(styles.tippy)}
      content="Rather than define our product by percentages, MONOGRAM experts use designations of light, medium or heavy to capture the sensory experience each strain provides."
      arrow={false}
      touch="hold"
    >
      {children}
    </Tippy>
  );
};

export default TippyTooltip;

const styles = StyleSheet.create({
  tippy: {
    position: 'relative',
    fontFamily: 'Titling Gothic FB Normal Standard',
    backgroundColor: black,
    color: white,
    border: `1px solid ${white}`,
    padding: '1rem',
  },
});
